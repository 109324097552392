(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

var consent;
window.addEventListener("load", function () {
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#252e39"
            },
            "button": {
                "background": "#14a7d0"
            }
        },
        "theme": "edgeless",
        "position": "top",
        "static": false,
        "content": {
            "href": "http://rs.gigroup.com/uslovi-koriscenja",
            "link": cookies.link,
            "message": cookies.message,
            "dismiss": cookies.dismiss,
            "allow": cookies.agree,
            "deny": cookies.deny
        }
    }, function (popup) {
        consent = popup;
    }, function (err) {
        console.error(err);
    });
});

function initAjaxLoad() {
    $('body').addClass('ajaxloading');
}

function completeAjaxLoad() {
    $('body').removeClass('ajaxloading');
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    beforeSend: function beforeSend() {
        initAjaxLoad();
    },
    success: function success() {
        completeAjaxLoad();
    },
    complete: function complete() {
        completeAjaxLoad();
    },
    error: function error() {
        completeAjaxLoad();
    }
});

$('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body',
    placement: 'top',
    html: true,
    delay: { "show": 500, "hide": 100 }
});
$('body').popover({
    container: 'body',
    selector: '[data-toggle="popover"]',
    html: true,
    trigger: 'hover focus',
    delay: { "show": 500, "hide": 100 }
});
$('body .dropdown-toggle').dropdown();

function setModalMaxHeight(element) {
    top.$element = $(element);
    top.$content = top.$element.find('.modal-content');
    var borderWidth = top.$content.outerHeight() - top.$content.innerHeight();
    var dialogMargin = $(window).width() < 768 ? 20 : 60;
    var contentHeight = $(window).height() - (dialogMargin + borderWidth);
    var headerHeight = top.$element.find('.modal-header').outerHeight() || 0;
    var footerHeight = top.$element.find('.modal-footer').outerHeight() || 0;
    var maxHeight = contentHeight - (headerHeight + footerHeight);

    top.$content.css({
        'overflow': 'hidden'
    });

    top.$element.find('.modal-body').css({
        'max-height': maxHeight,
        'overflow-y': 'auto'
    });
}

$('.modal').on('show.bs.modal', function () {
    $(this).show();
    if ($('#avatarModal').find(".modal-dialog").width() < $('.preview-container').width() + 35) {
        $('#avatarModal').find(".modal-dialog").addClass('modal-lg');
        $('.modal-dialog').trigger('contentchanged');
    } else {
        $('#avatarModal').find(".modal-dialog").removeClass('modal-lg');
        $('.modal-dialog').trigger('contentchanged');
    }
    setModalMaxHeight(this);
});

$(window).resize(function () {
    if ($('.modal.in').length != 0) {
        setModalMaxHeight($('.modal.in'));
    }
});
$('.modal-dialog').bind('contentchanged', function () {
    // do something after the div content has changed
    if ($('.modal.in').length != 0) {
        setModalMaxHeight($('.modal.in'));
    }
});
$('select').select2({
    theme: "bootstrap",
    placeholder: i18swal.select,
    language: locale,
    allowClear: true
});
$('.select2-container').tooltip({
    title: function title() {
        return $(this).prev().attr("data-original-title");
    },
    container: 'body',
    placement: 'top',
    html: true,
    delay: { "show": 500, "hide": 100 }
});
// Scroll Top Link
// Scroll Top Link
$('#footer-scroll-top-link').on('click', function (e) {
    e.preventDefault();
    $('html, body').stop().animate({
        scrollTop: 0
    }, 400);
});
$(":submit").on('click', function (event) {
    var form = $(this).parents('form:first');
    event.preventDefault();
    var self = this;
    setTimeout(function () {
        $(self).prop('disabled', true);
    }, 0);
    setTimeout(function () {
        $(form).submit();
    }, 1);
});

// RESPONSIVE MENU FN
$.gummMobileMenu = function gummMobileMenu(options, callback, element) {
    this.element = $(element);
    this.__construct(options, callback);
};

$.gummMobileMenu.settings = {
    button: '#mobile-menu-button',
    wrap: '#bluebox-wrap',
    menu: '#mobile-menu',
    items: 'li',
    subMenuItems: 'ul.sub-menu',
    dropdownLink: '.dropdown-link',
    dropDownClassOpen: 'icon-caret-down',
    dropDownClassClose: 'icon-caret-up'
};

$.gummMobileMenu.prototype = {
    state: 'closed',
    __construct: function __construct(options, callback) {
        this.options = $.extend(true, {}, $.gummMobileMenu.settings, options);
        this.button = $(this.options.button);
        this.wrap = $(this.options.wrap);
        this.items = this.element.find(this.options.items);
        this.dropdownButtons = this.items.find(this.options.dropdownLink);
        this.menu = $(this.options.menu);

        // this.menu.css({
        //     visibility: 'visible',
        //     display: 'none',
        //     opacity: 0
        // });
        this.bindListeners();
    },
    setSubemuDataHeight: function setSubemuDataHeight(submenuItem) {
        submenuItem.css({ display: 'block', 'height': 'auto' });
        submenuItem.data('height', submenuItem.outerHeight());
    },
    open: function open() {
        // if (!$('body').hasClass('mobile-menu-initialized')) {
        //     $('body').addClass('mobile-menu-initialized');
        // }
        var _self = this;
        // $('body').addClass('mobile-menu-active');
        this.menu.css({
            // opacity: 1,
            display: 'block'
        });
        this.wrap.transit({
            x: -250
        }, 500, function () {
            // console.log('yeah');
            _self.menu.css({
                zIndex: 0
            });
        });
        this.state = 'opened';
    },
    close: function close() {
        var _self = this;

        this.menu.css({
            zIndex: -1
        });
        this.wrap.transit({
            x: 0
        }, 500, function () {
            _self.menu.css({
                display: 'none'
            });
        });
        this.state = 'closed';
    },
    bindListeners: function bindListeners() {
        var _self = this;
        this.button.on('click', function (e) {
            e.preventDefault();
            if (_self.state === 'closed') {
                _self.open();
                // _self.menu.css({
                //     opacity: 1,
                //     display: 'block'
                // });
                // _self.wrap.transit({
                //     x: 250
                // }, 500);
                // _self.state = 'opened';
            } else {
                _self.close();
                // _self.wrap.transit({
                //     x: 0
                // }, 500, function(){
                //     _self.menu.css({
                //         opacity: 0,
                //         display: 'none'
                //     });
                // });
                // _self.state = 'closed';
            }
        });
        this.dropdownButtons.on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass(_self.options.dropDownClassClose)) {
                $(this).removeClass(_self.options.dropDownClassClose);
                $(this).addClass(_self.options.dropDownClassOpen);
                var submenuItem = $(this).parent().children(_self.options.subMenuItems);
                submenuItem.removeClass('dropdown-state-open').addClass('dropdown-state-close');
                //.css({
                // height: 0
                // });
                // $(this).parent().children(_self.options.subMenuItems).hide().animate({
                //     height: 0
                // }, 500);
                submenuItem.slideUp(400, 'easeInOutExpo');
            } else {
                $(this).removeClass(_self.options.dropDownClassOpen);
                $(this).addClass(_self.options.dropDownClassClose);
                var submenuItem = $(this).parent().children(_self.options.subMenuItems);
                submenuItem.slideDown(400, 'easeInOutExpo');
                submenuItem.addClass('dropdown-state-open').addClass('dropdown-state-open');
                //.css({
                // height: submenuItem.data('height')
                // });

                // console.log(submenuItem.data('height'));
                // submenuItem.show().animate({
                //     height: submenuItem.data('height')
                // }, 500, 'easeInOutExpo');
            }
        });

        // Some touch events thanks to hammer.js
        // try {
        //                 if (Modernizr.touch) {
        //                     this.wrap.hammer({
        //                         drag: false,
        //                         hold: false,
        //                         release: false,
        //                         swipe: true,
        //                         swipe_velocity: .5,
        //                         tap: false,
        //                         touch: false,
        //                         transform: false,
        //                         prevent_mouseevents: false
        //                     }).on('swiperight', function(e){
        //                         if (!$(e.target).hasClass('swipe-item')) {
        //                             e.preventDefault();
        //                             e.stopPropagation();
        //                             e.stopImmediatePropagation();
        //                             _self.open();
        //                         }
        //                     }).on('swipeleft', function(e){
        //                         if (!$(e.target).hasClass('swipe-item')) {
        //                             _self.close();
        //                         }
        //                     });
        //                 }
        //             } catch (err) {}

        this.wrap.on('touchmove', function (e) {
            if (_self.state === 'opened') {
                e.preventDefault();
            }
        });
    }
};

$.fn.gummMobileMenu = function gummMobileMenuFn(options, callback) {
    this.each(function () {
        var instance = $.data(this, 'gummMobileMenu');
        if (instance) {
            // update options of current instance
            // instance.update(options);
        } else {
            $.data(this, 'gummMobileMenu', new $.gummMobileMenu(options, callback, this));
        }
    });
    return this;
};
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
    $(".select2-search__field").attr("readonly", true);
}
$('#mobile-menu').gummMobileMenu();

},{}]},{},[1]);
